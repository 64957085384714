import _get from 'lodash/get';
import _pick from 'lodash/pick';
import { batch } from 'react-redux';

import { GQL_ASSETS_COUNT_INSPECT } from '@/graphql/asset/queries';

import {
  PROJECT_INITIALIZE,
  PROJECT_UPDATE,
  PROJECT_UPDATE_ANONYMIZATION,
  PROJECT_UPDATE_AUTHOR,
  PROJECT_UPDATE_INSTRUCTIONS,
} from './slice';
import {
  type CreateProjectPayload,
  type DeleteAssetsPayload,
  type DeleteProjectPayload,
  type GetProjectPayload,
  type ProjectState,
  type UpdateInstructionsPayload,
  type UpdateProjectAndProjectUserInReduxPayload,
  type UpdateProjectAnonymizationPayload,
  type UpdatePropertiesInProjectPayload,
} from './types';

import { type User } from '../../__generated__/globalTypes';
import { clientQuery } from '../../apollo';
import { GQL_DATASET_DELETE_MANY_FROM } from '../../graphql/asset/mutations';
import {
  GQL_PROJECT_DELETE_ASYNCHRONOUSLY,
  GQL_PROJECT_UPDATE_ANONYMIZATION,
  GQL_UPDATE_PROPERTIES_IN_PROJECT,
} from '../../graphql/project/mutations';
import { GQL_PROJECT_NO_KPIS_AND_PROJECT_USER_GET } from '../../graphql/project/queries';
import { SegmentEvents } from '../../pages/RootModule/helpers';
import { getQueueUrl } from '../../pages/projects/ProjectMenu/routes';
import extractJsonMalformedError from '../../services/jsonResponse/malformedError';
import { resetProjectInfos } from '../../zustand';
import { initialState } from '../../zustand/project-queue/initialState';
import {
  addNotification,
  forceRefreshReviewTable,
  updateField as applicationUpdateField,
} from '../application/actions';
import { CLEAR_UPLOAD_REPORT } from '../application/slice';
import {
  getNextAssetAndLabelFromProject,
  initializeState as assetInitializeState,
} from '../asset-label/actions';
import { updateLastSavedJson } from '../label-interface/actions';
import { PROJECT_USER_FETCHED, PROJECT_USER_UPDATE } from '../project-user/slice';
import { type AppThunk } from '../types';

export const initializeState = (): { type: string } => PROJECT_INITIALIZE();

export const createProject = (payload: CreateProjectPayload): AppThunk => {
  return async dispatch => {
    const { history, project, sendToSegment } = payload;
    try {
      const projectID = project?.id;
      dispatch(PROJECT_UPDATE(project));
      dispatch(updateLastSavedJson({}));
      dispatch(CLEAR_UPLOAD_REPORT());
      sendToSegment({
        event: SegmentEvents.CREATED_PROJECT,
        properties: {
          projectID,
          projectType: project?.inputType,
          title: project.title,
        },
      });
      if (projectID && history) history.push(getQueueUrl(projectID));
    } catch (err) {
      const errorMessage = _get(err, 'message', 'Error when creating project').replace(
        'GraphQL error: ',
        '',
      );
      dispatch(
        addNotification({
          message: errorMessage,
          variant: 'info',
        }),
      );
    } finally {
      dispatch(applicationUpdateField({ path: 'projectIsCreating', value: false }));
    }
  };
};

export const updateInstructions = (payload: UpdateInstructionsPayload): AppThunk => {
  const actionId = `updateInstructions`;

  return async (dispatch, getState) => {
    const { client } = payload;
    const { project } = getState();
    try {
      const data = _pick(project, ['instructions']);
      const where = { id: project?.id };
      const { data: mutationData } = await client.mutate({
        context: {
          clientName: 'V2',
          headers: {
            actionId,
          },
        },
        mutation: GQL_UPDATE_PROPERTIES_IN_PROJECT,
        variables: { data, where },
      });
      dispatch(
        addNotification({
          message: 'Instructions saved!',
          variant: 'success',
        }),
      );
      return dispatch(PROJECT_UPDATE(_get(mutationData, 'updatePropertiesInProject')));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(
          addNotification({
            message: error.message.replace('GraphQL error: ', ''),
            variant: 'info',
          }),
        );
      }
    }
  };
};

export const updateProjectInRedux = (payload: ProjectState): AppThunk => {
  return async dispatch => {
    return dispatch(PROJECT_UPDATE(payload));
  };
};

export const updateProjectAndProjectUserInRedux = (
  payload: UpdateProjectAndProjectUserInReduxPayload,
): AppThunk => {
  return async dispatch => {
    const project = _get(payload, 'projects[0]');
    if (project) {
      dispatch(PROJECT_UPDATE(project));
    }
    const projectUser = _get(payload, 'projectUsers[0]');
    if (projectUser) {
      dispatch(PROJECT_USER_UPDATE(projectUser));
      dispatch(PROJECT_USER_FETCHED());
    }
    dispatch(CLEAR_UPLOAD_REPORT());
  };
};

export const updatePropertiesInProject = (payload: UpdatePropertiesInProjectPayload): AppThunk => {
  const actionId = `updatePropertiesInProject`;
  return async dispatch => {
    const { client, data, where, successMessage, onSuccess = () => {} } = payload;
    const mutations = await client.mutate({
      context: {
        clientName: 'V2',
        headers: {
          actionId,
        },
      },
      mutation: GQL_UPDATE_PROPERTIES_IN_PROJECT,
      variables: { data, where },
    });
    const responseData = _get(mutations, 'data');
    const error = _get(mutations, 'errors.[0].message', '');
    const extractedError = extractJsonMalformedError(error);
    const cleanError = extractedError.replace(/\[.*?\]/g, '');
    if (cleanError) {
      dispatch(addNotification({ message: cleanError, variant: 'warning' }));
    } else {
      dispatch(addNotification({ message: successMessage, variant: 'success' }));
      onSuccess();
    }
    return dispatch(PROJECT_UPDATE(_get(responseData, 'updatePropertiesInProject')));
  };
};

export const getProject = (payload: GetProjectPayload): AppThunk => {
  const actionId = `getProject`;
  return async dispatch => {
    const { client, email, projectId } = payload;
    try {
      const response = await clientQuery({
        actionId,
        client,
        clientName: 'V2',
        dispatch,
        fetchPolicy: 'cache-first',
        query: GQL_PROJECT_NO_KPIS_AND_PROJECT_USER_GET,
        variables: {
          first: 1,
          projectUserWhere: { project: { id: projectId }, user: { email } },
          projectWhere: { id: projectId },
          skip: 0,
        },
        withLoader: false,
      });
      const data = response?.data;
      if (!data) return;
      const project = data?.projects?.[0];
      if (project) {
        dispatch(PROJECT_UPDATE(project));
        dispatch(CLEAR_UPLOAD_REPORT());
        dispatch(updateLastSavedJson({}));
      }
      const projectUser = data?.projectUsers?.[0];
      dispatch(PROJECT_USER_UPDATE(projectUser));
      dispatch(PROJECT_USER_FETCHED());
    } catch (error) {
      if (error instanceof Error) {
        dispatch(
          addNotification({
            message: error.message.replace('GraphQL error: ', ''),
            variant: 'info',
          }),
        );
      }
    }
  };
};

export const deleteAssets = (payload: DeleteAssetsPayload): AppThunk => {
  const actionId = `deleteAssets`;
  return async dispatch => {
    const {
      callback,
      client,
      projectID,
      where = {},
      shouldDeleteAllAssets,
      projectQueueWhere,
    } = payload;

    const response = await client.mutate({
      context: {
        clientName: 'V2',
        headers: {
          actionId,
        },
      },
      mutation: GQL_DATASET_DELETE_MANY_FROM,
      variables: {
        where: {
          ...where,
          ...(shouldDeleteAllAssets ? {} : projectQueueWhere),
          project: { id: projectID },
        },
      },
    });
    const data = _get(response, 'data');
    callback?.();
    if (!data) return;

    batch(async () => {
      dispatch(PROJECT_UPDATE(_get(data, 'deleteManyFromDataset')));
      dispatch(forceRefreshReviewTable());
      await dispatch(assetInitializeState());
      await client.query({
        fetchPolicy: 'network-only',
        query: GQL_ASSETS_COUNT_INSPECT,
        variables: { where: { project: { id: projectID } } },
      });
      dispatch(getNextAssetAndLabelFromProject({ client, projectID }));
      resetProjectInfos({
        ...initialState,
        areAllAssetsSelected: false,
      });
    });
  };
};

export const deleteProject = (payload: DeleteProjectPayload): AppThunk => {
  const actionId = `deleteProject`;
  return async dispatch => {
    const { client, projectID } = payload;
    dispatch(applicationUpdateField({ path: 'projectIsDeleting', value: true }));

    await client.mutate({
      context: {
        clientName: 'V2',
        headers: {
          actionId,
        },
      },
      mutation: GQL_PROJECT_DELETE_ASYNCHRONOUSLY,
      variables: { where: { id: projectID } },
    });
    dispatch(applicationUpdateField({ path: 'projectIsDeleting', value: false }));
  };
};

export const updateProjectAnonymization = ({
  client,
  projectId,
  shouldAnonymize,
}: UpdateProjectAnonymizationPayload): AppThunk => {
  const actionId = 'updateProjectAnonymization';
  return async dispatch => {
    await client.mutate({
      context: {
        clientName: 'V2',
        headers: {
          actionId,
        },
      },
      mutation: GQL_PROJECT_UPDATE_ANONYMIZATION,
      variables: { input: { id: projectId, shouldAnonymize } },
    });

    dispatch(PROJECT_UPDATE_ANONYMIZATION(shouldAnonymize));
  };
};

export const updateProjectAuthor = (newAuthor: User): AppThunk => {
  return async dispatch => {
    dispatch(PROJECT_UPDATE_AUTHOR(newAuthor));
  };
};
export const updateProjectInstructions = (newInstructions: string): AppThunk => {
  return async dispatch => {
    dispatch(PROJECT_UPDATE_INSTRUCTIONS(newInstructions));
  };
};
